import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const PrivacyPolicy = () => {
  return (
    <Container className="privacy-policy">
      <Row>
        <Col>
          <h1>Privacy Policy</h1>
      <p>
        ConnectionsGames.net ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website ConnectionsGames.net. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
      </p>
      <h2>1. Information We Collect</h2>
      <h3>Personal Information</h3>
      <p>
        We may collect personal information that you provide to us directly, such as your name, email address, and other contact details when you sign up for an account, subscribe to our newsletter, or contact us for support.
      </p>
      <h3>Usage Data</h3>
      <p>
        We collect information about how you interact with our website, including your IP address, browser type, pages visited, and the dates and times of your visits. This data helps us analyze trends, improve our website, and ensure a better user experience.
      </p>
      <h3>Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to enhance your experience on our site. Cookies are small files stored on your device that help us remember your preferences and track your usage of our site.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide, maintain, and improve our website and services.</li>
        <li>To personalize your experience and respond to your inquiries.</li>
        <li>To send you newsletters, updates, and promotional materials that you may find interesting.</li>
        <li>To analyze usage patterns and trends to improve our website and user experience.</li>
        <li>To ensure the security and integrity of our website.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to outside parties, except in the following circumstances:</p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website, conducting our business, or servicing you, provided they agree to keep your information confidential.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to a valid request by public authorities.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.
        </li>
      </ul>

      <h2>4. Your Choices</h2>
      <p>
        You have the right to access, correct, or delete your personal information. You can also opt out of receiving promotional communications from us by following the unsubscribe instructions in those communications.
      </p>

      <h2>5. Security</h2>
      <p>
        We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2>6. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of those third parties. We encourage you to review their privacy policies before providing any personal information.
      </p>

      <h2>7. Children’s Privacy</h2>
      <p>
        Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </p>
      <p><strong>ConnectionsGames.net</strong></p>
      <p>By using our website, you consent to our Privacy Policy and agree to its terms.</p>
    
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
