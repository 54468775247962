import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';


const faqs = [
  {
    question: "What makes the Connections Game different from other puzzles?",
    answer: "The NYTimes Connections Game challenges you to think outside the box by finding relationships between words. Unlike other puzzles, it combines wordplay with strategy."
  },
  {
    question: "Can I play the Connections Game offline?",
    answer: "No, the New York Times Connections Game requires an internet connection to access daily puzzles and updates. However, offline versions may be added in future updates."
  },
  {
    question: "Are there any in-game purchases?",
    answer: "The NYT Connections Game is free to play, but there are optional in-game purchases for hints or additional features."
  },
  {
    question: "How often are new puzzles added?",
    answer: "The Connections Game Today feature ensures new puzzles are added daily, so you always have something fresh to play."
  },
  {
    question: "Can I create my puzzles?",
    answer: "Currently, the Connections Game NYT does not allow user-created puzzles, but this feature might be introduced."
  }
];

function FAQSection() {
  return (
    <div className="faq-section">
      <h2 className="faq-heading">FAQ&apos;S</h2>
      <Accordion defaultActiveKey="0">
        {faqs.map((faq, index) => (
          <Card className="faq-card" key={index}>
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body>
                {faq.answer}
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    </div>
  );
}

export default FAQSection;
