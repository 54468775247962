import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function AboutUs() {
  return (
    <Container className="my-5 about-us">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <h1 className="mb-4 text-center">About Us</h1>
          <p className="lead">
          Welcome to ConnectionsGames.net, your ultimate destination for fun, engaging, and mind-challenging puzzle games! At ConnectionsGames.net, we	&apos;re dedicated to creating puzzles that stimulate your brain while providing hours of entertainment.
          </p><p> Whether you	&apos;re a seasoned puzzle enthusiast or just looking for a relaxing way to unwind, our collection of games offers something for everyone.
Our journey began with a simple mission: to blend creativity, logic, and fun into games that help people sharpen their thinking skills while enjoying themselves. Over time, we	&apos;ve grown into a passionate community of puzzle lovers who thrive on making connections, solving riddles, and tackling new challenges every day. 
</p><p>Our team works tirelessly to deliver fresh, exciting puzzles that leave you feeling satisfied and motivated to solve more.
At ConnectionsGames.net, we believe games are more than just a pastime—they	&apos;re a way to enhance critical thinking, boost memory, and spark creativity.</p><p>
 That	&apos;s why we strive to offer a wide range of puzzles that are not only fun but also mentally rewarding.
Thank you for being part of our growing community. We look forward to continuing to bring you puzzles that inspire, challenge, and entertain. Let	&apos;s connect, solve, and enjoy the journey together!

           </p>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
