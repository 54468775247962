import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, Card, Spinner, Col, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePageContent from './HomePageContent';
import Footer from './Footer';
import StylishSpinner from './StylishSpinner';
import Time from './Timer';

const Game = () => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [mistakes, setMistakes] = useState(0);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); 
    const [completedGroups, setCompletedGroups] = useState([]);
    const [gameComplete, setGameComplete] = useState(false);

    // Fetch list of JSON files
    useEffect(() => {
      const fetchFiles = async () => {
          try {
              const response = await axios.get('https://connectionsgames.net/gamedata/upload.php');
              const files = response.data;
  
              // Sort files by date in descending order
              const sortedFiles = files.sort((a, b) => {
                  const dateA = new Date(a.filename.replace('.json', '').split('-').reverse().join('-'));
                  const dateB = new Date(b.filename.replace('.json', '').split('-').reverse().join('-'));
                  return dateB - dateA;
              });
  
              setFiles(sortedFiles);
  
              // Automatically select the latest file and fetch its data
              const latestFile = sortedFiles[0]?.filename || '';
              if (latestFile) {
                  setSelectedFile(latestFile);
                  fetchData(latestFile);
              }
          } catch (error) {
              console.error('Error fetching files:', error);
          }
      };
  
      fetchFiles();
  }, []);
  

    // Fetch data for the selected file
    const fetchData = async (filename) => {
        try {
            setLoading(true);
            const response = await axios.get(`https://connectionsgames.net/gamedata/upload.php?file=${filename}`);
            const fileData = response.data.find(file => file.filename === filename)?.data || [];
            const shuffledData = shuffleArray(fileData.flatMap(group => group.members));
            setData(shuffledData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    // Handle file selection
    const handleSelect = (filename) => {
        setSelectedFile(filename);
        fetchData(filename);
        setSelectedItems([]);
        setCompletedGroups([]);
        setGameComplete(false);
        setMessage('');
    };

    // Shuffle array function
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Handle item click
    const handleItemClick = (item) => {
        setSelectedItems(prevItems => {
            const isAlreadySelected = prevItems.includes(item);
            if (isAlreadySelected) {
                return prevItems.filter(selected => selected !== item);
            } else {
                if (prevItems.length < 4) {
                    return [...prevItems, item];
                } else {
                    setMessage('You can only select up to 4 items.');
                    return prevItems;
                }
            }
        });
    };

// Handle form submission
const handleSubmit = () => {
  if (selectedItems.length !== 4) {
    setMessage('You need to select 4 items before submitting.');
    setMessageType('info'); // info message
      return;
  }

  // Convert selected items to a set for comparison
  const selectedItemsSet = new Set(selectedItems);
  console.log('Selected Items:', selectedItems);
  console.log('Selected Items Set:', Array.from(selectedItemsSet));

  // Fetch the data from the JSON file used in the game
  const fetchDataFromCurrentFile = async () => {
      try {
          const response = await axios.get(`https://connectionsgames.net/gamedata/upload.php?file=${selectedFile}`);
          const fileData = response.data.find(file => file.filename === selectedFile)?.data || [];
          
          // Find the group that matches the selected items
          const groupMatch = fileData.find(group => {
              if (!group || !group.members) return false;

              // Convert group members to a set for comparison
              const groupMembersSet = new Set(group.members);
              console.log('Group Members:', group.members);
              console.log('Group Members Set:', Array.from(groupMembersSet));

              // Compare sets
              return groupMembersSet.size === selectedItemsSet.size &&
                     [...groupMembersSet].every(member => selectedItemsSet.has(member));
          });

          console.log('Group Match:', groupMatch);

          if (groupMatch) {
              console.log('Matched Group:', groupMatch);
              setMessage('Success! You correctly identified the group.');
              setMessageType('success');
              setCompletedGroups(prev => [...prev, { groupName: groupMatch.group, members: groupMatch.members }]);

              // Remove matched items from the data
              setData(prevData => prevData.filter(member => !groupMatch.members.includes(member)));

              setSelectedItems([]);
              if (data.length <= 4) {
                  setGameComplete(true);
                  setMessage('Congratulations! You have completed all the groups.');
                  setMessageType('success');
              }
          } else {
              setMistakes(prevMistakes => {
                  if (prevMistakes < 3) {
                      setMessage(`Incorrect guess, please try again. Mistakes remaining: ${3 - prevMistakes}`);
                      setMessageType('warning');
                  } else {
                      setMessage('Game over! You have made 4 mistakes.');
                      setMessageType('danger');
                      setGameComplete(true);
                  }
                  return prevMistakes + 1;
              });
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  fetchDataFromCurrentFile();
};



    const handleDeselect = () => {
        setSelectedItems([]);
    };

    const startGameAgain = () => {
        setSelectedItems([]);
        setMistakes(0);
        setMessage('');
        setCompletedGroups([]);
        setGameComplete(false);
        fetchData(selectedFile); // Reload data for the current file
    };
    const getGroupClass = (index) => {
      const classes = ['bg1', 'bg2', 'bg3', 'bg4'];
      return classes[index % classes.length];
    };

    const handleRefresh = () => {
        window.location.reload(); // Refreshes the page
      };

    return (
      <>
        <div className="container mb-5 box-size">
            {/* <h1 className="mb-4 text-center">Connections Unlimited Game</h1> */}
            <div className='row p-0'>
                <h5 className='text-center mt-3 mb-0'>Create Correct Groups Four of Four!</h5>
                <Time />
            {completedGroups.length > 0 && (
                <div className="mt-4 group-success ">
                    {completedGroups.map((group, index) => (
                        <div key={index} className={`game-group ${getGroupClass(index)}`} style={{ padding: '10px', marginBottom: '10px' }}>
                            <h3 className="group-name">{group.groupName}</h3>
                            <div className="group-members">
                                {group.members.join(', ')}
                            </div>
                        </div>
                    ))}
                </div>
            )}
              </div>
            {loading ? (
                <StylishSpinner />
            ) : (
                <div className="row rowP">
                    {data.map((member, index) => (
                        <Col xs={3} sm={3} md={3} lg={3} key={index} className='start-card-col'>
                            <Card
                                onClick={() => handleItemClick(member)}
                                className='card-main'
                                style={{
                                  color: selectedItems.includes(member) ? '#fff' : '#000',
                                  backgroundColor: selectedItems.includes(member) ? '#5a594e' : '#efefe6'
                              }}
                            >
                                <Card.Body>{member}</Card.Body>
                            </Card>
                        </Col>
                    ))}
                </div>
            )}

            <div className="text-center mt-4">
                <Button onClick={handleRefresh} variant="light" className="mx-2">Shuffle</Button>
                <Button onClick={handleDeselect} variant="warning" className="mx-2">Deselect</Button>
                <Button onClick={handleSubmit} variant="success" className="me-2">Submit</Button>
            </div>

            {message && <Alert variant={messageType} className="text-center mt-3">{message}</Alert>}

            {gameComplete && (
                <div className="text-center mt-4">
                    <Button onClick={startGameAgain} variant="primary">Start Game Again</Button>
                </div>
            )}

            <div className='row text-center my-4'>
                <p className='text-center'>--Select an archive date--</p>
            <Dropdown>
    <Dropdown.Toggle variant="primary" id="dropdown-basic">
        {selectedFile ? selectedFile.replace('.json', '') : '--Select an archive date--'}
    </Dropdown.Toggle>

    <Dropdown.Menu>
        {files
            .sort((a, b) => {
                const dateA = new Date(a.filename.replace('.json', '').split('-').reverse().join('-'));
                const dateB = new Date(b.filename.replace('.json', '').split('-').reverse().join('-'));
                return dateB - dateA;
            })
            .map(file => (
                <Dropdown.Item key={file.filename} onClick={() => handleSelect(file.filename)}>
                    {file.filename.replace('.json', '')}
                </Dropdown.Item>
            ))}
    </Dropdown.Menu>
</Dropdown>


            </div>
           
        </div>
       
   
        <HomePageContent />

        </>
    );
};

export default Game;
